var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "business-partner" }, [
    _c("div", { staticClass: "partners" }, [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "reach-show",
                rawName: "v-reach-show",
                value: "elegant-in",
                expression: "'elegant-in'",
              },
            ],
          },
          [
            _c("div", { staticClass: "context-title" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("borrow.partners.title")) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "context-text partner-text" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("borrow.partners.text")) +
                  "\n                "
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.mounted
          ? _c(
              "div",
              {
                staticClass: "partners-box",
                style: { height: !_vm.isMdScreen ? ".4rem" : "" },
              },
              [
                _c(
                  "div",
                  [
                    _c("div", { staticClass: "cover cover1" }),
                    _vm._v(" "),
                    _c(
                      "vue-seamless-scroll",
                      {
                        key: _vm.scrollKey,
                        staticClass: "swiperWidth",
                        attrs: {
                          data: _vm.partnerList,
                          "class-option": _vm.classOption(
                            _vm.partnerList.length
                          ),
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "box-wrap" },
                          _vm._l(_vm.partnerList, function (it, ix) {
                            return _c(
                              "div",
                              { key: ix, staticClass: "box-item" },
                              [_c("img", { attrs: { src: it } })]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "cover cover2" }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }