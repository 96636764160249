<!-- @Author: wuhuiwang -->
<!-- @Date: 2023-11-13 14:01:35 -->
<!-- @Last Modified by: lijiahang -->
<!-- @Last Modified time: 2023-12-06 16:07:41 -->

<template>
    <div
        class="introduce"
    >
        <div class="introduce-wrapper">
            <div class="container">
                <div v-reach-show="'elegant-in'">
                    <div class="context-title introduce-title">
                        {{ $t('borrow.introduce.title') }}
                    </div>
                    <div class="context-text">
                        {{ $t('borrow.introduce.text') }}
                    </div>
                </div>
                <div class="advantage-content">
                    <div
                        v-for="(item, index) in $t('borrow.introduce.advantage')"
                        :key="index"
                        v-reach-show="'elegant-in'"
                        class="advantage-item"
                    >
                        <div
                            class="item-left"
                            :class="{'item-left-width': index !== 0}"
                        >
                            <div
                                class="context-title item-left-title item-text-left"
                                v-html="$t(item.title)"
                            />
                            <div class="context-text item-text-left">
                                {{ $t(item.text) }}
                            </div>
                        </div>
                        <div class="item-right">
                            <div class="back-img">
                                <img
                                    class="d-none d-md-block"
                                    :src="imgList[index]"
                                >
                                <img
                                    class="d-block d-md-none"
                                    :src="smallImgList[index]"
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">

import a1 from './img/01a.png';
import b1 from './img/01b.png';
import a2 from './img/02a.png';
import b2 from './img/02b.png';
import a3 from './img/03a.png';
import b3 from './img/03b.png';
import a4 from './img/04a.png';
import b4 from './img/04b.png';

export default {
    data() {
        return {
            imgList: [a1, a2, a3, a4],
            smallImgList: [b1, b2, b3, b4]
        };
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.introduce-wrapper {
    text-align: center;
    padding: 100px 0;

    @media screen and (max-width: $screen-md) {
        padding: 80px 0 40px;
    }

    .introduce-title {
        width: 65%;

        @media screen and (max-width: $screen-md) {
            width: 100%;
        }
    }

    .advantage-content {
        margin: 0 auto;

        .advantage-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 120px;

            .item-left {
                flex: 1;
                position: relative;

                .item-text-left {
                    text-align: left;
                }

                &-width {
                    flex: unset;
                    width: 480px;
                }
            }

            .item-right {
                width: 500px;
                height: 500px;
                flex-shrink: 0;

                .back-img {
                    img {
                        width: 100%;
                    }
                }
            }

            &:nth-child(even) {
                .item-right {
                    order: -1;
                }
            }

            @media screen and (max-width: $screen-md) {
                flex-direction: column-reverse;
                padding: 0 16px;
                margin-top: 0.32rem;

                &:first-child {
                    margin-top: 0.4rem;
                }

                .item-left {
                    .item-left-title {
                        font-size: 18px;
                    }

                    .item-text-left {
                        text-align: center;
                    }

                    &-width {
                        width: unset;
                    }
                }

                .item-right {
                    width: 100%;
                    height: fit-content;
                }

                &:nth-child(even) {
                    .item-right {
                        order: 0;
                    }
                }
            }
        }
    }
}
</style>
