var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "about" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "reach-show",
              rawName: "v-reach-show",
              value: "elegant-in",
              expression: "'elegant-in'",
            },
          ],
          staticClass: "about-ctn",
        },
        [
          _c("div", { staticClass: "context-title about-title" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.$t("borrow.about.title")) +
                "\n            "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "context-text about-title" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.$t("borrow.about.desc")) +
                "\n            "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "banner" }, [
            _c("div", { staticClass: "banner-title" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("borrow.about.banner.title")) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "banner-subTitle" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("borrow.about.banner.subTitle")) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "banner-desc" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("borrow.about.banner.desc")) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c("img", { attrs: { src: require("./img/banner.png") } }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card-box" },
            _vm._l(_vm.$t("borrow.about.cardList"), function (item, index) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "reach-show",
                      rawName: "v-reach-show",
                      value: "elegant-in",
                      expression: "'elegant-in'",
                    },
                  ],
                  key: index,
                  staticClass: "card-item",
                },
                [
                  index === 0 || (index === 1 && !_vm.isExpand)
                    ? _c("div", [
                        _c("div", { staticClass: "card-item-title" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(item.title) +
                              "\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-item-subTitle" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(item.subTitle) +
                              "\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-item-desc" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(item.desc) +
                              "\n                        "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  (index === 1 && _vm.isExpand) || _vm.isMobile
                    ? _c(
                        "div",
                        _vm._l(item.applicationList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "app-item" },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(item) +
                                  "\n                        "
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  item.interview
                    ? _c(
                        "div",
                        {
                          staticClass: "card-item-btn d-none d-md-flex",
                          on: {
                            click: function ($event) {
                              _vm.isExpand = !_vm.isExpand
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.interview) +
                              "\n                        "
                          ),
                          _c("img", {
                            class: { "arrow-reverse": _vm.isExpand },
                            attrs: { src: require("./img/arrow.svg") },
                          }),
                        ]
                      )
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }