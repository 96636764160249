<!-- @Author: wuhuiwang -->
<!-- @Date: 2023-11-15 15:43:44 -->
<!-- @Last Modified by: ruiwang -->
<!-- @Last Modified time: 2023-11-30 12:09:52 -->

<template>
    <div class="about">
        <div class="container">
            <div
                v-reach-show="'elegant-in'"
                class="about-ctn"
            >
                <div class="context-title about-title">
                    {{ $t('borrow.about.title') }}
                </div>
                <div class="context-text about-title">
                    {{ $t('borrow.about.desc') }}
                </div>
                <div class="banner">
                    <div class="banner-title">
                        {{ $t('borrow.about.banner.title') }}
                    </div>
                    <div class="banner-subTitle">
                        {{ $t('borrow.about.banner.subTitle') }}
                    </div>
                    <div class="banner-desc">
                        {{ $t('borrow.about.banner.desc') }}
                    </div>
                    <img src="./img/banner.png">
                </div>
                <div class="card-box">
                    <div
                        v-for="(item, index) in $t('borrow.about.cardList')"
                        :key="index"
                        v-reach-show="'elegant-in'"
                        class="card-item"
                    >
                        <div v-if="index === 0 || (index === 1 && !isExpand)">
                            <div class="card-item-title">
                                {{ item.title }}
                            </div>
                            <div class="card-item-subTitle">
                                {{ item.subTitle }}
                            </div>
                            <div class="card-item-desc">
                                {{ item.desc }}
                            </div>
                        </div>
                        <div v-if="(index === 1 && isExpand) || isMobile">
                            <div
                                v-for="(item, index) in item.applicationList"
                                :key="index"
                                class="app-item"
                            >
                                {{ item }}
                            </div>
                        </div>
                        <div
                            v-if="item.interview"
                            class="card-item-btn d-none d-md-flex"
                            @click="isExpand = !isExpand"
                        >
                            {{ item.interview }}
                            <img
                                src="./img/arrow.svg"
                                :class="{'arrow-reverse': isExpand}"
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BorrowAbout',

    data() {
        return {
            isExpand: false,
            isMobile: false
        };
    },

    mounted() {
        this.isMobile = window.innerWidth < 768;
    }
};
</script>

<style lang="scss" scoped>
.about {
    padding: 100px 0;
    font-family: Lexend;

    @media screen and (max-width: $screen-md) {
        padding: 40px 0;
    }

    &-ctn {
        width: 100%;

        .about-title {
            width: 65%;

            @media screen and (max-width: $screen-md) {
                width: 100%;
            }
        }

        .banner {
            width: 100%;
            padding: 60px;
            margin-top: 40px;
            border-radius: 40px;
            background: $base-black;
            position: relative;

            &-title {
                color: $base-white;
                font-size: 32px;
                font-weight: 700;
                line-height: 130%;
            }

            &-subTitle {
                color: $black-75-l;
                font-size: 16px;
                font-weight: 700;
                line-height: 160%;
            }

            &-desc {
                width: 45%;
                margin-top: 40px;
                color: #92929f;
                font-size: 16px;
                font-weight: 400;
                line-height: 160%;
            }

            img {
                position: absolute;
                width: 360px;
                height: 360px;
                top: 0;
                right: 80px;
                z-index: 1;
            }

            @media screen and (max-width: $screen-md) {
                padding: 210px 16px 20px;
                border-radius: 20px;

                &-title {
                    font-size: 20px;
                    text-align: center;
                }

                &-subTitle {
                    margin-top: 8px;
                    font-size: 14px;
                    line-height: 130%;
                    text-align: center;
                }

                &-desc {
                    width: 100%;
                    margin-top: 16px;
                    font-size: 14px;
                }

                img {
                    width: auto;
                    height: 160px;
                    top: 20px;
                    right: unset;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }

        .card-box {
            margin-top: 40px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            @media screen and (max-width: $screen-md) {
                margin-top: 20px;
                flex-direction: column;
            }

            .card-item {
                width: 50%;
                padding: 40px;
                border-radius: 40px;
                background: $black-98-l;
                margin-right: 40px;

                &:last-child {
                    margin-right: 0;
                }

                &-title {
                    color: $base-black;
                    font-size: 32px;
                    font-weight: 700;
                    line-height: 145%;
                }

                &-subTitle {
                    color: $black-45-l;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 160%;
                }

                &-desc {
                    margin-top: 20px;
                    color: $black-45-l;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 160%;
                }

                &-btn {
                    width: fit-content;
                    margin-top: 40px;
                    padding: 12px 20px;
                    border-radius: 12px;
                    background: $black-92-l;
                    color: $black-45-l;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 130%;
                    cursor: pointer;
                    display: flex;
                    align-items: center;

                    img {
                        width: 16px;
                        height: 12px;
                        margin-left: 12px;
                        transition: all 0.3s ease-in-out;
                    }

                    .arrow-reverse {
                        transform: rotate(180deg);
                    }
                }

                .app-item {
                    color: $black-45-l;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 160%;
                    display: flex;
                    align-items: center;

                    &::before {
                        display: inline-block;
                        content: "";
                        width: 3px;
                        height: 3px;
                        border-radius: 50%;
                        background: $black-45-l;
                        margin-right: 5px;
                    }

                    @media screen and (max-width: $screen-md) {
                        font-size: 14px;
                    }
                }

                @media screen and (max-width: $screen-md) {
                    width: 100%;
                    padding: 20px 16px;
                    margin-right: 0;
                    background: $base-white;
                    margin-bottom: 20px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &-title {
                        font-size: 20px;
                        line-height: 130%;
                    }

                    &-subTitle {
                        font-size: 14px;
                        line-height: 130%;
                        margin-top: 4px;
                    }

                    &-desc {
                        margin-top: 16px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
</style>
