var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "portrait" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "reach-show",
            rawName: "v-reach-show",
            value: "elegant-in",
            expression: "'elegant-in'",
          },
        ],
        staticClass: "container",
      },
      [
        _c("div", { staticClass: "context-title portrait-title" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$t("borrow.portrait.title")) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "context-text portrait-title" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$t("borrow.portrait.text")) +
              "\n        "
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "reach-show",
            rawName: "v-reach-show",
            value: "elegant-in",
            expression: "'elegant-in'",
          },
        ],
        staticClass: "portrait-content",
      },
      [
        _c("div", { staticClass: "d-none d-md-flex" }, [
          _c(
            "div",
            { staticClass: "comment-box" },
            _vm._l(
              _vm.$t("borrow.portrait.commentList"),
              function (item, index) {
                return _c("div", { key: index }, [
                  index % 2 === 0
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "reach-show",
                              rawName: "v-reach-show",
                              value: "comment" + index + "-in",
                              expression: "`comment${index}-in`",
                            },
                          ],
                          staticClass: "comment-item",
                          class: ["comment" + index],
                        },
                        [
                          _c("div", { staticClass: "head-pic" }, [
                            _c("img", { attrs: { src: _vm.headList[index] } }),
                            _vm._v(" "),
                            _c("span", { staticClass: "name" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "date" }, [
                              _vm._v(_vm._s(item.date)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "head-info" }, [
                            _c("div", { staticClass: "text" }, [
                              _c("span", { staticClass: "text-cm" }, [
                                _vm._v("@Credmex"),
                              ]),
                              _vm._v(
                                _vm._s(item.text) +
                                  "\n                            "
                              ),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ])
              }
            ),
            0
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content-center" }, [
          _c("img", {
            staticClass: "badge",
            attrs: { src: require("./img/badge.png") },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "score-star",
            attrs: { src: require("./img/star.png") },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "score-detail" },
            _vm._l(
              _vm.$t("borrow.portrait.scoreDetail"),
              function (itemn, index) {
                return _c("div", { key: index, staticClass: "detail-item" }, [
                  itemn
                    ? _c("div", { staticClass: "detail-item-number" }, [
                        itemn.number === "4.7"
                          ? _c("div", { staticClass: "number-star" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(itemn.number)
                              ),
                              _c("img", {
                                attrs: { src: require("./img/star-w.png") },
                              }),
                            ])
                          : _c("div", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(itemn.number) +
                                  "\n                        "
                              ),
                            ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "detail-item-desc" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(itemn.desc) +
                        "\n                    "
                    ),
                  ]),
                ])
              }
            ),
            0
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-none d-md-flex" }, [
          _c(
            "div",
            { staticClass: "comment-box" },
            _vm._l(
              _vm.$t("borrow.portrait.commentList"),
              function (item, index) {
                return _c("div", { key: index }, [
                  index % 2 === 1
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "reach-show",
                              rawName: "v-reach-show",
                              value: "comment" + index + "-in",
                              expression: "`comment${index}-in`",
                            },
                          ],
                          staticClass: "comment-item",
                          class: ["comment" + index],
                        },
                        [
                          _c("div", { staticClass: "head-pic" }, [
                            _c("img", { attrs: { src: _vm.headList[index] } }),
                            _vm._v(" "),
                            _c("span", { staticClass: "name" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "date" }, [
                              _vm._v(_vm._s(item.date)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "head-info" }, [
                            _c("div", { staticClass: "text" }, [
                              _c("span", { staticClass: "text-cm" }, [
                                _vm._v("@Credmex"),
                              ]),
                              _vm._v(
                                _vm._s(item.text) +
                                  "\n                            "
                              ),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ])
              }
            ),
            0
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "reach-show",
            rawName: "v-reach-show",
            value: "elegant-in",
            expression: "'elegant-in'",
          },
        ],
        staticClass: "d-block d-md-none",
      },
      [
        _c(
          "div",
          { staticClass: "comment-swipe" },
          [
            _c(
              "swiper",
              {
                staticClass: "my-swiper",
                attrs: { options: _vm.swiperOption },
              },
              _vm._l(
                _vm.$t("borrow.portrait.commentList"),
                function (item, index) {
                  return _c("swiper-slide", { key: index }, [
                    _c("div", { staticClass: "head-pic" }, [
                      _c("img", { attrs: { src: _vm.headList[index] } }),
                      _vm._v(" "),
                      _c("span", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "date" }, [
                        _vm._v(_vm._s(item.date)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "head-info" }, [
                      _c("div", { staticClass: "text" }, [
                        _c("span", { staticClass: "text-cm" }, [
                          _vm._v("@Credmex"),
                        ]),
                        _vm._v(
                          _vm._s(item.text) + "\n                        "
                        ),
                      ]),
                    ]),
                  ])
                }
              ),
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }