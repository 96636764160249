<!-- @Author: wuhuiwang -->
<!-- @Date: 2023-11-13 11:06:35 -->
<!-- @Last Modified by: zhaoyang -->
<!-- @Last Modified time: 2023-11-27 11:47:43 -->

<template>
    <div class="banner-ani">
        <div class="ani-ball" />
        <div class="ani-bg" />
    </div>
</template>

<script type="text/babel">
export default {
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
::-webkit-scrollbar {
    display: none;
}

.banner-ani {
    width: 100%;
    height: 100vh;
    background-color: $base-black;
    margin: auto;
    position: absolute;
    z-index: 9999;
    left: 0;
    right: 0;
    top: 0;
    border-radius: 0;
    animation: banner-ani-fade 1s linear 2s forwards;

    @keyframes banner-ani-fade {
        100% {
            height: 88vh;
            width: calc(100% - 80px);
            top: 80px;
            border-radius: 60px;
            display: none;
        }
    }

    .ani-ball {
        width: 128px;
        height: 128px;
        border-radius: 50%;
        background-image: url("./img/ball.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        animation: ani-ball-fade 3s linear 0s;

        @keyframes ani-ball-fade {
            0% {
                transform: scale(1);
            }

            12.5% {
                transform: scale(0.6);
            }

            25% {
                transform: scale(1);
            }

            37.5% {
                transform: scale(0.6);
            }

            50% {
                transform: scale(1);
                opacity: 1;
            }

            70% {
                transform: scale(1);
                opacity: 1;
            }

            100% {
                transform: scale(1);
                opacity: 0;
            }
        }
    }

    .ani-bg {
        width: 100%;
        height: 100%;
        opacity: 0;
        background-image: url("./img/texture.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        animation: ani-bg-fade 1s linear 2s forwards;

        @keyframes ani-bg-fade {
            100% {
                opacity: 1;
            }
        }
    }
}
</style>
