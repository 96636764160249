/*
 * @Author: chengyuzhang
 * @Date: 2021-12-10 18:51:25
 * @Last Modified by: chengyuzhang
 * @Last Modified time: 2021-12-10 18:53:10
 */

/**
 * return language local except zh
 */
export default {
    methods: {
        getLocal() {
            const {locale} = this.$i18n;

            return locale === 'zh' ? this.$i18n.fallbackLocale : locale;
        }
    }
};
