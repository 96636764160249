var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "blog" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "reach-show",
              rawName: "v-reach-show",
              value: "elegant-in",
              expression: "'elegant-in'",
            },
          ],
          staticClass: "blog-ctn",
          on: { click: _vm.handleToArticleDetail },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "blog-list" },
            [
              _c("div", { staticClass: "blog-logo" }, [
                _vm._v("\n                    Blog\n                "),
              ]),
              _vm._v(" "),
              _c(
                "swiper",
                {
                  ref: "mySwiper",
                  staticClass: "blog-swiper",
                  attrs: { options: _vm.swiperOptions },
                },
                [
                  _vm._l(_vm.blogList, function (item, index) {
                    return _c("swiper-slide", { key: index }, [
                      _c("div", { staticClass: "slide-box" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.blogTitle(item)) +
                              "\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "date" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.formatTime(item.timeCreated)) +
                              "\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "desc" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.blogContent(item)) +
                              "\n                            "
                          ),
                        ]),
                      ]),
                    ])
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "swiper-pagination",
                    attrs: { slot: "pagination" },
                    slot: "pagination",
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c("img", {
                staticClass: "jump",
                attrs: { src: require("./img/jump.png") },
              }),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "blog-bg" }, [
      _c("img", {
        staticClass: "bg-ctn",
        attrs: { src: require("./img/bwc.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }