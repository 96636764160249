var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "process" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "reach-show",
              rawName: "v-reach-show",
              value: "elegant-in",
              expression: "'elegant-in'",
            },
          ],
        },
        [
          _c("div", { staticClass: "context-title process-title" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.$t("borrow.process.title")) +
                "\n            "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "context-text process-title" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.$t("borrow.process.text")) +
                "\n            "
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "d-none d-md-block" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "reach-show",
                rawName: "v-reach-show",
                value: "elegant-in",
                expression: "'elegant-in'",
              },
            ],
            staticClass: "process-content",
          },
          _vm._l(_vm.$t("borrow.process.items"), function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "content-card",
                class: { "content-card-s": _vm.currentIndex !== index },
                style: { background: item.bgColor },
                on: {
                  click: function ($event) {
                    return _vm.handleClickCard(index)
                  },
                },
              },
              [
                _c("div", { staticClass: "card-left" }, [
                  _c(
                    "div",
                    {
                      staticClass: "number",
                      class: { "number-w": index !== 0 },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(index + 1) +
                          ".\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "title-box" }, [
                    _c(
                      "div",
                      {
                        staticClass: "title",
                        class: {
                          "title-w": index !== 0,
                          "title-top": index === 2,
                        },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(item.title) +
                            "\n                            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "title-s",
                        class: { "title-s-w": index !== 0 },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(item.title) +
                            "\n                            "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "desc",
                      class: { "desc-w": index !== 0, "desc-w2": index === 2 },
                      style: {
                        top: _vm.getTop(index),
                      },
                    },
                    [
                      _c("div", [_vm._v(_vm._s(item.text))]),
                      _vm._v(" "),
                      item.textList
                        ? _c(
                            "div",
                            _vm._l(item.textList, function (text, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "desc-item" },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(text) +
                                      "\n                                "
                                  ),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-right" }, [
                  _c("img", {
                    staticClass: "right-img",
                    attrs: { src: _vm.stepList[index] },
                  }),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "reach-show",
              rawName: "v-reach-show",
              value: "elegant-in",
              expression: "'elegant-in'",
            },
          ],
          staticClass: "process-swipe d-block d-md-none",
        },
        [
          _c(
            "swiper",
            { staticClass: "my-swiper", attrs: { options: _vm.swiperOption } },
            _vm._l(_vm.$t("borrow.process.items"), function (item, index) {
              return _c(
                "swiper-slide",
                { key: index, style: { background: item.bgColor } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "number",
                      class: { "number-w": index !== 0 },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(index + 1) +
                          ".\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "title", class: { "title-w": index !== 0 } },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(item.title) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "desc",
                      class: { "desc-w": index !== 0, "desc-w2": index === 2 },
                    },
                    [
                      _c("div", [_vm._v(_vm._s(item.text))]),
                      _vm._v(" "),
                      item.textList
                        ? _c(
                            "div",
                            _vm._l(item.textList, function (text, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "desc-item" },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(text) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "right-img",
                    attrs: { src: _vm.stepList[index] },
                  }),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }