<!-- @Author: wuhuiwang -->
<!-- @Date: 2023-11-14 16:28:41 -->
<!-- @Last Modified by: lijiahang -->
<!-- @Last Modified time: 2023-12-06 16:07:41 -->

<template>
    <div class="portrait">
        <div
            v-reach-show="'elegant-in'"
            class="container"
        >
            <div class="context-title portrait-title">
                {{ $t('borrow.portrait.title') }}
            </div>
            <div class="context-text portrait-title">
                {{ $t('borrow.portrait.text') }}
            </div>
        </div>
        <div
            v-reach-show="'elegant-in'"
            class="portrait-content"
        >
            <div class="d-none d-md-flex">
                <div class="comment-box">
                    <div
                        v-for="(item, index) in $t('borrow.portrait.commentList')"
                        :key="index"
                    >
                        <div
                            v-if="index % 2 === 0"
                            v-reach-show="`comment${index}-in`"
                            class="comment-item"
                            :class="[`comment${index}`]"
                        >
                            <div class="head-pic">
                                <img :src="headList[index]">
                                <span class="name">{{ item.name }}</span>
                                <span class="date">{{ item.date }}</span>
                            </div>
                            <div class="head-info">
                                <div class="text">
                                    <span class="text-cm">@Credmex</span>{{ item.text }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-center">
                <img
                    class="badge"
                    src="./img/badge.png"
                >
                <img
                    class="score-star"
                    src="./img/star.png"
                >
                <div class="score-detail">
                    <div
                        v-for="(itemn, index) in $t('borrow.portrait.scoreDetail')"
                        :key="index"
                        class="detail-item"
                    >
                        <div
                            v-if="itemn"
                            class="detail-item-number"
                        >
                            <div
                                v-if="itemn.number === '4.7'"
                                class="number-star"
                            >
                                {{ itemn.number }}<img src="./img/star-w.png">
                            </div>
                            <div v-else>
                                {{ itemn.number }}
                            </div>
                        </div>
                        <div class="detail-item-desc">
                            {{ itemn.desc }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-none d-md-flex">
                <div class="comment-box">
                    <div
                        v-for="(item, index) in $t('borrow.portrait.commentList')"
                        :key="index"
                    >
                        <div
                            v-if="index % 2 === 1"
                            v-reach-show="`comment${index}-in`"
                            class="comment-item"
                            :class="[`comment${index}`]"
                        >
                            <div class="head-pic">
                                <img :src="headList[index]">
                                <span class="name">{{ item.name }}</span>
                                <span class="date">{{ item.date }}</span>
                            </div>
                            <div class="head-info">
                                <div class="text">
                                    <span class="text-cm">@Credmex</span>{{ item.text }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-reach-show="'elegant-in'"
            class="d-block d-md-none"
        >
            <div class="comment-swipe">
                <swiper
                    class="my-swiper"
                    :options="swiperOption"
                >
                    <swiper-slide
                        v-for="(item, index) in $t('borrow.portrait.commentList')"
                        :key="index"
                    >
                        <div class="head-pic">
                            <img :src="headList[index]">
                            <span class="name">{{ item.name }}</span>
                            <span class="date">{{ item.date }}</span>
                        </div>
                        <div class="head-info">
                            <div class="text">
                                <span class="text-cm">@Credmex</span>{{ item.text }}
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';

import 'swiper/css/swiper.css';
import head1 from './img/head1.png';
import head2 from './img/head2.png';
import head3 from './img/head3.png';
import head4 from './img/head4.png';
import head5 from './img/head5.png';
import head6 from './img/head6.png';

export default {
    name: 'BorrowPortrait',

    components: {
        Swiper,
        SwiperSlide
    },

    data() {
        return {
            headList: [head1, head2, head3, head4, head5, head6],
            swiperOption: {
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false
                },
                loop: true,
                slidesPerView: 'auto',
                spaceBetween: 16,
                centeredSlides: true
            }
        };
    }
};
</script>

<style lang="scss" scoped>
.portrait {
    padding: 100px 0;
    font-family: Lexend;

    @media screen and (max-width: $screen-md) {
        padding: 40px 0;
    }

    &-title {
        width: 65%;

        @media screen and (max-width: $screen-md) {
            width: 100%;
        }
    }

    &-content {
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: $screen-md) {
            margin-top: 40px;
        }

        .comment-box {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .comment-item {
                width: 298px;
                padding: 16px;
                border-radius: 32px;
                background: $black-98-l;
                transition: all 0.5s ease-in-out;

                &:hover {
                    transform: translateY(-20px);
                }
            }

            .comment0 {
                margin-top: -16px;
            }

            .comment1 {
                opacity: 0;

                &-in {
                    animation: show-fade 1s linear 0.3s forwards;
                }
            }

            .comment2 {
                margin: 40px 0 40px -40px;
                opacity: 0;

                &-in {
                    animation: show-fade 1s linear 0.6s forwards;
                }
            }

            .comment3 {
                margin: 40px 0 40px 40px;
                opacity: 0;

                &-in {
                    animation: show-fade 1s linear 0.9s forwards;
                }
            }

            .comment4 {
                margin-bottom: 8px;
                opacity: 0;

                &-in {
                    animation: show-fade 1s linear 1.2s forwards;
                }
            }

            .comment5 {
                margin-bottom: -16px;
                opacity: 0;

                &-in {
                    animation: show-fade 1s linear 1.5s forwards;
                }
            }

            @keyframes show-fade {
                0% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }
        }

        .content-center {
            display: flex;
            width: 512px;
            padding: 233px 22px 30px;
            flex-direction: column;
            align-items: center;
            border-radius: 40px;
            border: 10px solid $black-30-l;
            background: $base-black;
            margin: 0 60px;
            position: relative;

            .badge {
                position: absolute;
                top: -24px;
                width: 176px;
                height: auto;
            }

            .score-star {
                width: 240px;
                height: 32px;
            }

            .score-detail {
                margin-top: 48px;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                text-align: center;
                width: 100%;

                .detail-item {
                    width: calc(100% / 3);
                    margin-right: 12px;

                    &:last-child {
                        margin-right: 0;
                    }

                    &-number {
                        color: $base-white;
                        font-size: 24px;
                        font-weight: 700;
                        line-height: 145%;
                        display: flex;
                        justify-content: center;

                        .number-star {
                            display: flex;
                            align-items: center;
                            width: fit-content;

                            img {
                                width: 15px;
                                height: 15px;
                            }
                        }
                    }

                    &-desc {
                        margin-top: 8px;
                        color: $black-45-l;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 145%;
                    }
                }
            }

            @media screen and (max-width: $screen-md) {
                width: calc(100% - 32px);
                border: 8px solid $black-30-l;
                padding: 149px 16px 30px;
                border-radius: 20px;
                margin: 0;

                .badge {
                    top: -17px;
                    width: 120px;
                    height: 150px;
                }

                .score-star {
                    width: 200px;
                    height: 24px;
                }

                .score-detail {
                    margin-top: 20px;
                    flex-wrap: wrap;

                    .detail-item {
                        margin-right: 0;
                        width: 50%;

                        &:nth-child(2) {
                            display: none;
                        }

                        &-number {
                            font-size: 20px;
                            line-height: 130%;
                        }

                        &-desc {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }

    .head-pic {
        width: 100%;
        height: auto;
        background-color: unset;
        display: flex;
        align-items: center;

        img {
            width: 32px;
            height: 32px;
            background-color: $purple-25-l;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 8px;
        }

        .name {
            font-weight: 700;
            margin-right: 10px;
        }

        .date {
            font-weight: 400;
            color: #9f9faa;
        }
    }

    .head-info {
        flex: 1;
        margin-left: 40px;

        .text {
            color: $black-15-l;
            font-size: 12px;
            font-weight: 400;
            line-height: 140%;

            &-cm {
                color: $primary-purple;
                margin-right: 10px;
            }
        }
    }

    .comment-swipe {
        margin: 20px -16px 0;

        .my-swiper {
            ::v-deep {
                .swiper-wrapper {
                    .swiper-slide {
                        width: 65%;
                        height: fit-content;
                        margin-right: 0;
                        border-radius: 32px;
                        padding: 16px;
                        background: $black-98-l;
                        position: relative;
                    }
                }
            }
        }
    }
}
</style>
