var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "faq" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "reach-show",
              rawName: "v-reach-show",
              value: "elegant-in",
              expression: "'elegant-in'",
            },
          ],
          staticClass: "faq-ctn",
          on: { click: _vm.handleToFaq },
        },
        [
          _c("div", { staticClass: "faq-ctn-left" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("borrow.faq.title")) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "desc" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("borrow.faq.desc")) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c("img", { attrs: { src: require("./img/arrow.png") } }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "faq-ctn-right" },
            _vm._l(_vm.$t("borrow.faq.list"), function (item, index) {
              return _c("div", { key: index, staticClass: "faq-item" }, [
                _c("div", { staticClass: "faq-item-content" }, [
                  _c("div", [_vm._v(_vm._s(item))]),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "d-none d-md-block",
                    attrs: { src: require("./img/arrow-w.png") },
                  }),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "d-block d-md-none",
                    attrs: { src: require("./img/arrow-s.png") },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "line d-block d-md-none" }),
              ])
            }),
            0
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }