<!-- @Author: zhaoyang -->
<!-- @Date: 2021-12-03 13:18:32.776 -->
<!-- @Last Modified by: lijiahang -->
<!-- @Last Modified time: 2023-11-28 14:25:26 -->

<template>
    <div class="business-partner">
        <div class="partners">
            <div class="container">
                <div v-reach-show="'elegant-in'">
                    <div class="context-title">
                        {{ $t('borrow.partners.title') }}
                    </div>
                    <div class="context-text partner-text">
                        {{ $t('borrow.partners.text') }}
                    </div>
                </div>
                <div
                    v-if="mounted"
                    class="partners-box"
                    :style="{height: !isMdScreen ? '.4rem' : ''}"
                >
                    <div>
                        <div class="cover cover1" />
                        <vue-seamless-scroll
                            :key="scrollKey"
                            :data="partnerList"
                            :class-option="classOption(partnerList.length)"
                            class="swiperWidth"
                        >
                            <div class="box-wrap">
                                <div
                                    v-for="(it, ix) in partnerList"
                                    :key="ix"
                                    class="box-item"
                                >
                                    <img :src="it">
                                </div>
                            </div>
                        </vue-seamless-scroll>
                        <div class="cover cover2" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
import GoogleIMG from './img/google.png';
import HUAWEI from './img/huawei.png';
import BC from './img/logo-bc.png';
import CIRCULO from './img/logo-circulo.png';
import PAYCASH from './img/logo-paycash.png';
import SPEI from './img/logo-spei.png';
import STP from './img/logo-stp.png';
import META from './img/meta.png';
import OPENPAY from './img/openpay.png';

export default {
    name: 'BusinessPartner',
    data() {
        return {
            scrollKey: 1,
            mounted: false,
            isMdScreen: true,
            timer: null,
            partnerList: [
                SPEI,
                STP,
                PAYCASH,
                BC,
                CIRCULO,
                META,
                GoogleIMG,
                OPENPAY,
                HUAWEI

            ]
        };
    },

    computed: {
        classOption() {
            return function (num) {
                return {
                    step: 1,
                    limitMoveNum: num,
                    hoverStop: false,
                    direction: 2,
                    openTouch: true,
                    isSingleRemUnit: true,
                    openWatch: true,
                    screenWidth: ''
                };
            };
        }
    },

    mounted() {
        this.$options.components.vueSeamlessScroll = () => import('vue-seamless-scroll');
        this.scrollKey = Date.now();
        this.changeScreen();
        this.mounted = true;
        window.addEventListener('resize', this.changeScreen);
    },

    beforeDestroy() {
        clearTimeout(this.timer);
        window.removeEventListener('resize', this.changeScreen);
    },

    methods: {
        changeScreen() {
            const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            this.screenWidth = screenWidth;
            this.isMdScreen = screenWidth > 768;
            if (this.screenWidth !== screenWidth) {
                this.timer = setTimeout(() => {
                    this.scrollKey = Date.now();
                }, 100);
            }
        }
    }
};

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .business-partner {
        padding: 100px 0;

        @media screen and (max-width: $screen-md) {
            padding: 40px 0;
        }

        .partners {
            text-align: center;

            .partner-text {
                width: 65%;

                @media screen and (max-width: $screen-md) {
                    width: 100%;
                }
            }

            .partners-image {
                padding: 50px 0 0;

                &-list {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    max-width: 100vw;
                }

                .partner-item {
                    margin: 0 25px;
                    height: 75px;

                    img {
                        display: block;
                        height: 100%;
                        width: auto;
                    }

                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    @media screen and (max-width: $screen-md) {
                        margin: 0;
                        height: 40px;
                        margin-bottom: 20px;
                        margin-right: 20px;

                        &:nth-child(3n) {
                            margin-right: 0;
                        }
                    }
                }

                @media screen and (max-width: $screen-md) {
                    padding: 0 30px;
                }
            }
        }
    }

    .partners-box {
        height: 80px;
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;

        .box-wrap {
            display: flex;
            flex-wrap: nowrap;
            width: fit-content;
            height: 100%;

            .box-item {
                height: auto;
                position: relative;
                flex-shrink: 0;
                margin-right: 60px;

                img {
                    width: auto;
                    height: 80px;
                }

                @media screen and (max-width: $screen-md) {
                    margin-right: 30px;

                    img {
                        height: 40px;
                    }
                }
            }
        }

        .cover {
            width: 80px;
            height: 100%;
            position: absolute;
            top: 0;
            z-index: 99;
        }

        .cover1 {
            left: 0;
            background: linear-gradient(to right, rgb(255, 255, 255), rgba(255, 255, 255, 0));
        }

        .cover2 {
            right: 0;
            background: linear-gradient(to left, rgb(255, 255, 255), rgba(255, 255, 255, 0));
        }

        .swiperWidth {
            display: flex;
            width: 100%;
            overflow: hidden;

            ::v-deep {
                div {
                    display: flex !important;
                    overflow: unset !important;
                }
            }
        }
    }

</style>
