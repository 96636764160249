var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "introduce" }, [
    _c("div", { staticClass: "introduce-wrapper" }, [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "reach-show",
                rawName: "v-reach-show",
                value: "elegant-in",
                expression: "'elegant-in'",
              },
            ],
          },
          [
            _c("div", { staticClass: "context-title introduce-title" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("borrow.introduce.title")) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "context-text" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("borrow.introduce.text")) +
                  "\n                "
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "advantage-content" },
          _vm._l(_vm.$t("borrow.introduce.advantage"), function (item, index) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "reach-show",
                    rawName: "v-reach-show",
                    value: "elegant-in",
                    expression: "'elegant-in'",
                  },
                ],
                key: index,
                staticClass: "advantage-item",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "item-left",
                    class: { "item-left-width": index !== 0 },
                  },
                  [
                    _c("div", {
                      staticClass:
                        "context-title item-left-title item-text-left",
                      domProps: { innerHTML: _vm._s(_vm.$t(item.title)) },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "context-text item-text-left" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t(item.text)) +
                          "\n                        "
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "item-right" }, [
                  _c("div", { staticClass: "back-img" }, [
                    _c("img", {
                      staticClass: "d-none d-md-block",
                      attrs: { src: _vm.imgList[index] },
                    }),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "d-block d-md-none",
                      attrs: { src: _vm.smallImgList[index] },
                    }),
                  ]),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }