<template>
    <div class="faq">
        <div class="container">
            <div
                v-reach-show="'elegant-in'"
                class="faq-ctn"
                @click="handleToFaq"
            >
                <div class="faq-ctn-left">
                    <div class="title">
                        {{ $t('borrow.faq.title') }}
                    </div>
                    <div class="desc">
                        {{ $t('borrow.faq.desc') }}
                    </div>
                    <img src="./img/arrow.png">
                </div>
                <div class="faq-ctn-right">
                    <div
                        v-for="(item, index) in $t('borrow.faq.list')"
                        :key="index"
                        class="faq-item"
                    >
                        <div class="faq-item-content">
                            <div>{{ item }}</div>
                            <img
                                class="d-none d-md-block"
                                src="./img/arrow-w.png"
                            >
                            <img
                                class="d-block d-md-none"
                                src="./img/arrow-s.png"
                            >
                        </div>
                        <div class="line d-block d-md-none" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BorrowFaq',

    methods: {
        handleToFaq() {
            this.$router.push({name: 'Faq'});
        }
    }
};
</script>

<style lang="scss" scoped>
.faq {
    padding: 100px 0;
    font-family: Lexend;

    @media screen and (max-width: $screen-md) {
        padding: 40px 0;
    }

    &-ctn {
        width: 100%;
        border-radius: 40px;
        background: $black-98-l;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        cursor: pointer;

        @media screen and (max-width: $screen-md) {
            border-radius: 20px;
            flex-direction: column;
        }

        &-left {
            width: 500px;
            padding: 60px;

            .title {
                color: $base-black;
                font-size: 40px;
                font-weight: 700;
                line-height: 130%;
            }

            .desc {
                margin-top: 20px;
                color: $black-45-l;
                font-size: 18px;
                font-weight: 400;
                line-height: 160%;
            }

            img {
                position: absolute;
                width: 60px;
                height: 60px;
                left: 60px;
                bottom: 60px;
            }

            @media screen and (max-width: $screen-md) {
                width: 100%;
                padding: 20px 16px;

                .title {
                    font-size: 20px;
                    width: 73%;
                }

                .desc {
                    font-size: 14px;
                }

                img {
                    width: 36px;
                    height: 36px;
                    left: unset;
                    bottom: unset;
                    top: 16px;
                    right: 20px;
                }
            }
        }

        &-right {
            flex: 1;
            margin-left: 40px;

            .faq-item {
                width: 100%;
                border-bottom: 1px solid $black-92-l;
                padding: 20px 24px;

                &-content {
                    height: 77px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: $base-black;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 160%;
                }

                &:last-child {
                    border-bottom: none;
                }

                &:hover {
                    background-color: $base-white;
                }

                img {
                    width: 40px;
                    height: 40px;
                    margin-left: 32px;
                }
            }

            @media screen and (max-width: $screen-md) {
                margin-left: 0;
                padding: 0;

                .faq-item {
                    border-bottom: none;
                    padding: 16px 16px 0;

                    &-content {
                        height: fit-content;
                        font-size: 14px;
                    }

                    img {
                        width: 38px;
                        height: 38px;
                        margin-left: 12px;
                    }

                    .line {
                        width: 100%;
                        height: 1px;
                        margin: 16px auto 0;
                        background-color: $black-92-l;
                    }

                    &:last-child {
                        .line {
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
}
</style>
