var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "borrow" },
    [
      _c("borrow-banner", { attrs: { "show-animation": _vm.showAnimation } }),
      _vm._v(" "),
      _c("borrow-introduce"),
      _vm._v(" "),
      _c("borrow-process"),
      _vm._v(" "),
      _c("borrow-portrait"),
      _vm._v(" "),
      _c("borrow-blog"),
      _vm._v(" "),
      _c("borrow-partner"),
      _vm._v(" "),
      _c("borrow-faq"),
      _vm._v(" "),
      _c("borrow-about"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }