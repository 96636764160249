<!-- @Author: wuhuiwang -->
<!-- @Date: 2023-11-13 11:06:51 -->
<!-- @Last Modified by: lijiahang -->
<!-- @Last Modified time: 2023-12-07 11:32:29 -->

<template>
    <div class="process">
        <div class="container">
            <div v-reach-show="'elegant-in'">
                <div class="context-title process-title">
                    {{ $t('borrow.process.title') }}
                </div>
                <div class="context-text process-title">
                    {{ $t('borrow.process.text') }}
                </div>
            </div>
            <div class="d-none d-md-block">
                <div
                    v-reach-show="'elegant-in'"
                    class="process-content"
                >
                    <div
                        v-for="(item, index) in $t('borrow.process.items')"
                        :key="index"
                        class="content-card"
                        :class="{'content-card-s': currentIndex !== index}"
                        :style="{'background': item.bgColor}"
                        @click="handleClickCard(index)"
                    >
                        <div class="card-left">
                            <div
                                class="number"
                                :class="{'number-w': index !==0}"
                            >
                                {{ index + 1 }}.
                            </div>
                            <div class="title-box">
                                <div
                                    class="title"
                                    :class="{'title-w': index !==0, 'title-top': index === 2}"
                                >
                                    {{ item.title }}
                                </div>
                                <div
                                    class="title-s"
                                    :class="{'title-s-w': index !==0}"
                                >
                                    {{ item.title }}
                                </div>
                            </div>
                            <div
                                class="desc"
                                :class="{'desc-w': index !==0, 'desc-w2': index === 2}"
                                :style="{
                                    top: getTop(index)
                                }"
                            >
                                <div>{{ item.text }}</div>
                                <div v-if="item.textList">
                                    <div
                                        v-for="(text, index) in item.textList"
                                        :key="index"
                                        class="desc-item"
                                    >
                                        {{ text }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-right">
                            <img
                                class="right-img"
                                :src="stepList[index]"
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-reach-show="'elegant-in'"
                class="process-swipe d-block d-md-none"
            >
                <swiper
                    class="my-swiper"
                    :options="swiperOption"
                >
                    <swiper-slide
                        v-for="(item, index) in $t('borrow.process.items')"
                        :key="index"
                        :style="{'background': item.bgColor}"
                    >
                        <div
                            class="number"
                            :class="{'number-w': index !==0}"
                        >
                            {{ index + 1 }}.
                        </div>
                        <div
                            class="title"
                            :class="{'title-w': index !==0}"
                        >
                            {{ item.title }}
                        </div>
                        <div
                            class="desc"
                            :class="{'desc-w': index !==0, 'desc-w2': index === 2}"
                        >
                            <div>{{ item.text }}</div>
                            <div v-if="item.textList">
                                <div
                                    v-for="(text, index) in item.textList"
                                    :key="index"
                                    class="desc-item"
                                >
                                    {{ text }}
                                </div>
                            </div>
                        </div>
                        <img
                            class="right-img"
                            :src="stepList[index]"
                        >
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';

import 'swiper/css/swiper.css';
import step1 from './img/step1.png';
import step2 from './img/step2.png';
import step3 from './img/step3.png';

export default {
    name: 'HomeProcess',

    components: {
        Swiper,
        SwiperSlide
    },

    data() {
        return {
            currentIndex: 0,
            isMobile: false,
            stepList: [step1, step2, step3],
            swiperOption: {
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false
                },
                loop: true,
                slidesPerView: 'auto',
                spaceBetween: 16,
                centeredSlides: true
            }
        };
    },

    mounted() {
        this.isMobile = window.innerWidth < 768;
        this.timer = setInterval(() => {
            this.currentIndex = (this.currentIndex + 1) % 3;
        }, 4000);
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },

    methods: {
        getTop(index) {
            if (index === 0) {
                return '200px';
            }

            if (index === 1) {
                return this.$i18n.locale === 'es' ? '210px' : '164px';
            }

            if (index === 2) {
                return this.$i18n.locale === 'es' ? '164px' : '200px';
            }
        },

        handleClickCard(index) {
            if (index === this.currentIndex) return;
            clearInterval(this.timer);
            this.currentIndex = index;
            this.timer = setInterval(() => {
                this.currentIndex = (this.currentIndex + 1) % 3;
            }, 4000);
        }
    }
};

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.process {
    padding: 100px 0;
    font-family: Lexend;

    @media screen and (max-width: $screen-md) {
        padding: 40px 0;
    }

    .process-title {
        width: 65%;
        margin-left: 0;
        text-align: left;

        @media screen and (max-width: $screen-md) {
            width: 100%;
        }
    }

    .process-content {
        display: flex;
        justify-content: space-between;

        .content-card {
            position: relative;
            width: 720px;
            height: 466px;
            border-radius: 40px;
            display: flex;
            align-items: flex-start;
            padding: 32px 40px 48px;
            overflow: hidden;
            cursor: pointer;
            margin-right: 20px;
            transition: all cubic-bezier(0.15, 0.64, 0.43, 0.88) 0.6s;

            &:last-child {
                margin-right: 0;
            }

            .card-left {
                width: 296px;
                transition: all cubic-bezier(0.15, 0.64, 0.43, 0.88) 0.6s;

                .number {
                    color: #000;
                    font-family: Fugaz One;
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 145%;

                    &-w {
                        color: $base-white;
                    }
                }

                .title-box {
                    .title {
                        position: absolute;
                        top: 102px;
                        width: 296px;
                        margin-top: 8px;
                        color: #1b1b23;
                        font-size: 32px;
                        font-weight: 700;
                        line-height: 130%;
                        opacity: 1;
                        transition: all cubic-bezier(0.15, 0.64, 0.43, 0.88) 0.6s;

                        &-w {
                            color: $base-white;
                        }

                        // &-top {
                        //     top: 122px;
                        // }
                    }

                    .title-s {
                        position: absolute;
                        top: 102px;
                        width: 180px;
                        margin-top: 6px;
                        color: #1b1b23;
                        font-size: 24px;
                        font-weight: 700;
                        line-height: 145%;
                        opacity: 0;
                        transition: all cubic-bezier(0.15, 0.64, 0.43, 0.88) 0.6s;

                        &-w {
                            color: $base-white;
                        }
                    }
                }

                .desc {
                    position: absolute;
                    top: 206px;
                    width: 296px;
                    color: #5c5c6a;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 160%;
                    transition: all cubic-bezier(0.15, 0.64, 0.43, 0.88) 0.6s;
                    opacity: 1;

                    &-w {
                        color: $black-92-l;
                    }

                    &-w2 {
                        color: $black-75-l;
                    }

                    &-item {
                        display: inline-flex;
                        align-items: center;

                        &::before {
                            content: "";
                            width: 3px;
                            height: 3px;
                            margin: 0 5px 0 10px;
                            border-radius: 50%;
                            background-color: #5c5c6a;
                        }
                    }
                }
            }

            .card-right {
                flex: 1;

                .right-img {
                    position: absolute;
                    width: 366px;
                    height: auto;
                    right: 0;
                    bottom: 0;
                    transition: all cubic-bezier(0.15, 0.64, 0.43, 0.88) 0.6s;
                }
            }
        }

        .content-card-s {
            width: 260px;
            height: 466px;
            padding: 34px 36px;
            flex-direction: column;

            .card-left {
                width: 100%;

                .title-box {
                    .title {
                        opacity: 0;
                    }

                    .title-s {
                        opacity: 1;
                    }
                }

                .desc {
                    opacity: 0;
                }
            }

            .card-right {
                flex: 1;

                .right-img {
                    position: absolute;
                    width: 214px;
                    height: auto;
                    right: -40px;
                    bottom: -7px;
                }
            }

            &:hover {
                .card-right {
                    .right-img {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }

    .process-swipe {
        margin: 0 -16px;

        .my-swiper {
            ::v-deep {
                .swiper-wrapper {
                    .swiper-slide {
                        width: 65%;
                        height: 404px;
                        margin-right: 0;
                        border-radius: 20px;
                        padding: 20px 16px;
                        position: relative;

                        .number {
                            color: #000;
                            font-family: Fugaz One;
                            font-size: 28px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 145%;

                            &-w {
                                color: $base-white;
                            }
                        }

                        .title {
                            margin: 8px 0;
                            color: $base-black;
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 160%;

                            &-w {
                                color: $base-white;
                            }
                        }

                        .desc {
                            color: $black-30-l;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 145%;

                            &-w {
                                color: $black-92-l;
                            }

                            &-w2 {
                                color: $black-75-l;
                            }

                            &-item {
                                display: flex;
                                align-items: center;

                                &::before {
                                    content: "";
                                    width: 3px;
                                    height: 3px;
                                    margin: 0 5px 0 10px;
                                    border-radius: 50%;
                                    background-color: #5c5c6a;
                                }
                            }
                        }

                        img {
                            position: absolute;
                            bottom: 0;
                            left: 50%;
                            transform: translateX(-50%);
                            height: 272px;
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}
</style>
