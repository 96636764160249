var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "banner" }, [
    _c(
      "div",
      { staticClass: "d-none d-md-block" },
      [_vm.showAnimation ? _c("bg-animation") : _vm._e()],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "banner-ctn" }, [
      _c(
        "div",
        {
          staticClass: "text-box",
          style: {
            "animation-delay": _vm.showAnimation ? "3.5s" : "0s",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "title-box d-none d-md-block",
              class: { "title-box-en": _vm.getLocal() === "en" },
            },
            [
              _c("div", { staticClass: "title1" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.getI18n("banner.title1")) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "title2" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.getI18n("banner.title2")) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "title3" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.getI18n("banner.title3")) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "code-box",
                  style: {
                    right: _vm.$i18n.locale === "es" ? "34px" : "100px",
                  },
                },
                [
                  _c("img", {
                    staticClass: "code",
                    attrs: { src: require("../../../img/ews.svg"), alt: "" },
                  }),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "code-min",
                    attrs: { src: require("../../../img/code.png"), alt: "" },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "download" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.getI18n("banner.download")) +
                        "\n                    "
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "reach-show",
                  rawName: "v-reach-show",
                  value: "elegant-in",
                  expression: "'elegant-in'",
                },
              ],
              staticClass: "title-md d-block d-md-none",
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.getI18n("banner.title")) +
                  "\n            "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "reach-show",
                  rawName: "v-reach-show",
                  value: "elegant-in",
                  expression: "'elegant-in'",
                },
              ],
              staticClass: "desc-box",
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.getI18n("banner.text")) +
                  "\n            "
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "d-none d-md-block" }, [
            _c("div", { staticClass: "store-wrap" }, [
              _c(
                "div",
                {
                  staticClass: "wrap-item",
                  on: {
                    click: function ($event) {
                      return _vm.handleDownload("apple")
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: { src: require("./img/appstore-pc.png"), alt: "" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "wrap-item android-item",
                  on: {
                    click: function ($event) {
                      return _vm.handleDownload("google")
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("./img/google-play-pc.png"),
                      alt: "",
                    },
                  }),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "d-block d-md-none" }, [
        _c("div", { staticClass: "download-box" }, [
          _c("img", {
            staticClass: "channel",
            attrs: { src: require("./img/new-m-applestore.png"), alt: "" },
            on: {
              click: function ($event) {
                return _vm.handleDownload("apple")
              },
            },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "channel",
            attrs: { src: require("./img/new-m-google.png"), alt: "" },
            on: {
              click: function ($event) {
                return _vm.handleDownload("google")
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "download-btn",
              on: {
                click: function ($event) {
                  return _vm.handleDownload("")
                },
              },
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.getI18n("banner.downloads")))]),
              _vm._v(" "),
              _c("img", {
                staticClass: "jump",
                attrs: { src: require("./img/jump.png"), alt: "" },
              }),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }