<!-- @Author: wuhuiwang -->
<!-- @Date: 2023-11-10 14:59:29 -->
<!-- @Last Modified by: zhaoyang -->
<!-- @Last Modified time: 2023-11-27 11:47:42 -->

<template>
    <div class="borrow">
        <borrow-banner :show-animation="showAnimation" />
        <borrow-introduce />
        <borrow-process />
        <borrow-portrait />
        <borrow-blog />
        <borrow-partner />
        <borrow-faq />
        <borrow-about />
    </div>
</template>

<script type="text/babel">
import BorrowAbout from './component/borrow/about';
import BorrowBanner from './component/borrow/banner';
import BorrowBlog from './component/borrow/blog';
import BorrowFaq from './component/borrow/faq';
import BorrowIntroduce from './component/borrow/introduce';
import BorrowPartner from './component/borrow/partner';
import BorrowPortrait from './component/borrow/portrait';
import BorrowProcess from './component/borrow/process';

export default {
    name: 'Borrow',

    components: {
        BorrowBanner,
        BorrowIntroduce,
        BorrowProcess,
        BorrowPortrait,
        BorrowBlog,
        BorrowPartner,
        BorrowFaq,
        BorrowAbout
    },

    props: {
        showAnimation: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {

        };
    },

    head() {
        const keywords = 'credmex,credmex es confiable,prestamo,prestamos personales,prestamos en linea,credito,kueski prestamos,tala,tala prestamos,tala dinero,didi prestamo,prestamos didi,baubap,superapoyo';
        const description = 'Ten la vida que deseas con Credmex.';

        return {
            meta: [
                {name: 'keywords', content: keywords},
                {name: 'description', content: description}
            ],
            link: [
                {rel: 'canonical', href: `https://credmex.com.mx${this.$route.path}`}
            ]
        };
    },

    mounted() {
        if (history.scrollRestoration) {
            history.scrollRestoration = 'manual';
        }
    },

    title() {
        return 'Préstamos personales en línea, solicita hasta $30,000 pesos, 100% confiable en méxico';
    }
};

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.borrow {
    width: 100%;
    overflow-x: hidden;
}
</style>
