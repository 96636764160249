/*
 * @Author: egafrandika
 * @Date: 2023-03-31 13:46:50
 * @Last Modified by: kenneth
 * @Last Modified time: 2023-12-13 13:54:59
 */

import Resource from '@yqg/resource';

import {getEurekaHost} from 'easycash/common/constant/config';

const api = {
    getHost: getEurekaHost,

    listActive: {
        url: '/api/officialWebsiteBlog/listActive',
        method: 'get'
    },
    homeActive: {
        url: '/api/officialWebsiteBlog/listHomePage',
        method: 'get'
    },
    getBlog: {
        url: '/api/officialWebsiteBlog/get/:path',
        method: 'get'
    },
    getCategoryType: {
        url: '/api/officialWebsiteBlog/getCategoryType',
        method: 'get'
    }
};

export default Resource.create(api);
