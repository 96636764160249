/*
 * @Author: xiaoyaqu
 * @Date: 2019-05-28 15:38:53
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-04-06 16:49:19
 */
/* global __API_HOST__ RUN_PORT */
export const rootTitle = 'Easycash';
export const API_HOST = __API_HOST__;
export const getEurekaHost = () => (typeof window !== 'undefined' && window.location.origin)
    || `http://127.0.0.1:${RUN_PORT}`;
export const BRAND = 'easycash';
export const noStageTitle = ['feat-overseas'];
