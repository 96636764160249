<!-- @Author: wuhuiwang -->
<!-- @Date: 2023-11-13 11:06:44 -->
<!-- @Last Modified by: zhaoyang -->
<!-- @Last Modified time: 2023-11-27 11:47:43 -->

<template>
    <div class="banner">
        <div class="d-none d-md-block">
            <bg-animation v-if="showAnimation" />
        </div>
        <div class="banner-ctn">
            <div
                class="text-box"
                :style="{
                    'animation-delay': showAnimation ? '3.5s' : '0s'
                }"
            >
                <div
                    class="title-box d-none d-md-block"
                    :class="{'title-box-en': getLocal() === 'en'}"
                >
                    <div class="title1">
                        {{ getI18n('banner.title1') }}
                    </div>
                    <div class="title2">
                        {{ getI18n('banner.title2') }}
                    </div>
                    <div class="title3">
                        {{ getI18n('banner.title3') }}
                    </div>
                    <div
                        class="code-box"
                        :style="{
                            right: $i18n.locale === 'es' ? '34px': '100px'
                        }"
                    >
                        <img
                            class="code"
                            src="../../../img/ews.svg"
                            alt=""
                        >
                        <img
                            class="code-min"
                            src="../../../img/code.png"
                            alt=""
                        >
                        <div class="download">
                            {{ getI18n('banner.download') }}
                        </div>
                    </div>
                </div>
                <div
                    v-reach-show="'elegant-in'"
                    class="title-md d-block d-md-none"
                >
                    {{ getI18n('banner.title') }}
                </div>
                <div
                    v-reach-show="'elegant-in'"
                    class="desc-box"
                >
                    {{ getI18n('banner.text') }}
                </div>
                <div class="d-none d-md-block">
                    <div class="store-wrap">
                        <div
                            class="wrap-item"
                            @click="handleDownload('apple')"
                        >
                            <img
                                src="./img/appstore-pc.png"
                                alt=""
                            >
                        </div>
                        <div
                            class="wrap-item android-item"
                            @click="handleDownload('google')"
                        >
                            <img
                                src="./img/google-play-pc.png"
                                alt=""
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-block d-md-none">
                <div class="download-box">
                    <img
                        class="channel"
                        src="./img/new-m-applestore.png"
                        alt=""
                        @click="handleDownload('apple')"
                    >
                    <img
                        class="channel"
                        src="./img/new-m-google.png"
                        alt=""
                        @click="handleDownload('google')"
                    >
                    <div
                        class="download-btn"
                        @click="handleDownload('')"
                    >
                        <span>{{ getI18n('banner.downloads') }}</span>
                        <img
                            class="jump"
                            src="./img/jump.png"
                            alt=""
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
import {downloadUrl, appleDownloadUrl} from 'credmex-new/common/constant/config';

import getLocal from 'easycash/common/mixin/get-local';

import bgAnimation from './bg-animation.vue';

export default {
    components: {
        bgAnimation
    },

    mixins: [getLocal],

    props: {
        showAnimation: {
            type: Boolean,
            default: true
        }
    },
    data() {
      return {
        isFlipped: false,
        isFlippedandr: false
      };
    },

    methods: {
        getI18n(text) {
            return this.$t(`borrow.${text}`);
        },

        handleDownload(type) {
            const redirectUrl = 'https://credmex.onelink.me/cf5y/4iva3ujp';
            // eslint-disable-next-line no-nested-ternary
            const url = type === 'google' ? downloadUrl : type === 'apple' ? appleDownloadUrl : redirectUrl;
            window.open(url);
        }
    }
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
    display: none;
}

.banner {
    height: 88vh;
    width: calc(100% - 80px);
    margin: auto;
    background-color: $base-black;
    border-radius: 60px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: Lexend;
    background-image: url("./img/texture.png");

    @media screen and (max-width: $screen-md) {
        width: calc(100% - 32px);
        height: 520px;
        border-radius: 20px;
        background-image: url("./img/texture-md.png");
    }

    &-ctn {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .text-box {
        width: 716px;
        height: fit-content;
        position: absolute;
        left: 50%;
        top: 50%;
        opacity: 0;
        transform: translate(-50%, -50%);
        animation: banner-text-fade 1s linear 3.5s forwards;

        @keyframes banner-text-fade {
            0% {
                top: calc(50% + 50px);
                opacity: 0;
            }

            100% {
                top: 50%;
                opacity: 1;
            }
        }

        @media screen and (max-width: $screen-md) {
            width: 100%;
            padding: 0 40px;
            margin-left: 0;
            opacity: 1;
            position: relative;
            top: 120px;
            transform: translateX(-50%);
            animation: none;
        }

        .title-box {
            margin-left: 33px;
            width: 716px;
            color: #fff;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 1.35;
            position: relative;

            @media screen and (max-width: $screen-md) {
                width: 100%;
            }

            .title1 {
                font-size: 44px;
            }

            .title2 {
                font-size: 44px;
                margin-left: -33px;
            }

            .title3 {
                font-size: 48px;
            }

            .code-box {
                position: absolute;
                bottom: 12.61px;
                width: 197px;

                .code-min {
                    width: 84px;
                    height: 84px;
                    position: absolute;
                    top: 26px;
                    right: 10px;
                }

                .code {
                    width: 100%;
                    height: auto;

                    &-en {
                        right: 0;
                    }
                }

                .download {
                    width: 100px;
                    position: absolute;
                    left: 10px;
                    bottom: 30px;
                    color: #1e1e1e;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: normal;
                    text-transform: capitalize;
                }
            }

            &-en {
                box-sizing: border-box;
                padding-left: 20px;
                margin-left: 40px;

                .title1 {
                    font-size: 48px;
                    margin-left: 45px;
                }

                .title2 {
                    margin-left: 15px;
                    font-size: 48px;
                }

                .title3 {
                    font-size: 48px;
                }
            }
        }

        .title-md {
            color: #fff;
            font-size: 20px;
            font-weight: 700;
            line-height: 1.3;
            text-align: center;
        }

        .desc-box {
            margin-top: 20px;
            color: $black-45-l;
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            line-height: 160%;

            @media screen and (max-width: $screen-md) {
                margin-top: 25px;
                color: $black-75-l;
                font-size: 14px;
            }
        }
    }

    .download-box {
        position: absolute;
        width: 100%;
        padding: 0 24px;
        bottom: 40px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .channel {
            width: 48px;
            height: 48px;
        }

        .download-btn {
            width: 152px;
            height: 48px;
            border-radius: 24px;
            background-color: $primary-green;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #1e1e1e;
            font-size: 16px;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
            padding-left: 10px;

            .jump {
                width: 40px;
                height: 40px;
            }
        }
    }

    .new-download-box {
        width: 100%;
        position: absolute;
        width: 100%;
        padding: 0 64px;
        bottom: 100px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .icon-apple {
            width: 120px;
            height: 39px;
        }

        .icon-android {
            width: 120px;
            height: 39px;
        }
    }
}

.flip-container-wrap {
    margin: 40px auto 0;
    display: flex;
    width: 500px;
    justify-content: center;
}

.flip-container {
    perspective: 1000px;
    width: 200px;
    height: 200px;
}

.andro {
    margin-left: 20px;
}

.flipper {
    transform-style: preserve-3d;
    position: relative;
    animation: flipAnimationReverse 1s forwards;
}

.front,
.back {
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
}

.front {
    width: 100%;
    height: 100%;
    transform: rotateY(0deg);
}

.back {
    transform: rotateY(180deg);
    width: 100%;
    height: 100%;
}

.flipped {
    animation: flipBack 1s forwards;
}

@keyframes flipBack {
    0% {
        transform: rotateY(0deg);
    }

    50% {
        transform: rotateY(200deg);
    }

    75% {
        transform: rotateY(160deg);
    }

    100% {
        transform: rotateY(180deg);
    }
}

@keyframes flipAnimationReverse {
    0% {
        transform: rotateY(180deg);
    }

    50% {
        transform: rotateY(-20deg);
    }

    75% {
        transform: rotateY(20deg);
    }

    100% {
        transform: rotateY(0deg);
    }
}

.store-wrap {
    display: flex;
    justify-content: center;
    margin-top: 48px;

    .wrap-item {
        width: 153px;
        height: 53px;
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
        }

        &:hover {
            box-shadow: 0 8px 35px 0 #0f0f12;
            margin-top: -2px;
        }
    }

    .android-item {
        margin-left: 24px;
    }
}

</style>
